.sidebar{
    z-index:5500;
}
.bg-wh{
    background:#fff;
}
.wfp--loading-overlay{
    background-color:#fff ;
    opacity:0.7;
    z-index:100000 !important;
}
.excel-form td{
    position:relative;
}
.excel-form .ss-main{
    padding:0px 0px 0px 3px;
}
.ss-main .ss-single-selected.ss-disabled{
    background-color:#dcdee2 !important;
}
.hov-u:hover{
    text-decoration: underline;
    cursor:pointer;
}
.s-label{
    font-size:12px !important;
}
/*p{
    font-size:0.9rem;
}*/
.wfp--form-group__title{
    font-size:0.9rem;
}
.link-list li{
    line-height:1.5em;
}
.wfp--tooltip{
    display:none;
}
.wfp--tooltip--visible{
    display:block;
}
.wrap-x-scroll{
    overflow-x:auto;
    border:1px solid #dfdfdf;
}
.mframe-filter-wrap .wfp--form-group__title{
    margin-bottom:0px;
}
.button-box button,.button-box>form>label{
    margin-bottom:5px;
}
.button-box>*:last-child{
    margin-bottom:0px;
}
form .wfp--form-item--invalid{
    padding-bottom:10px;
}
.wfp--number-box .wfp--input-wrapper{
    position:relative;
}
.wfp--table td{
    font-size:0.7rem;
    position:relative;
}
.wfp--modal{
    background-color:rgba(255,255,255, 0.5) !important;
}
.wfp--table{
    width:auto;
    min-width:100%;
    border-bottom: 1px solid #dfdfdf;
    table-layout: fixed;
}
.wfp--main-navigation,main-wrap{
    z-index:1100;
}
.col-item{
    min-width:150px;
}
.col-status{
    min-width:65px;
}
.wrap-stock-table{
    min-height:95%;
}
.wrap-stock-table>div{
    min-height:70vh;
}
.wrap-stock-table>div>table{
    border:1px solid #dfdfdf;
}
.m-frame-map-wrap{
    min-height:500px;
    z-index:0 !important;
}
.has-sort{
    cursor:pointer;
}
.num{
    text-align:right;
   /* min-width:95px;*/
}
.s-num{
    text-align:right;
    min-width:50px
}
.wfp--table th{
    text-align:left;
    position:sticky;
    top:0px;
    z-index:1;
    box-shadow:0px -2px 0px inset #aaa;
    vertical-align:middle;

}
.wfp--table thead,.wfp--table thead th{

    border-bottom:none !important;
    border-right:1px solid #ddd;
}
.wfp--search-close{
    display:none !important;
}
.lib-file-wrap a{
    font-size:1rem;
}
.lib-file-wrap ul li{
    list-style: disc !important;
    display:list-item;
    font-size:0.8rem;
    color:#777;
}
.gr-text{
    font-size:0.8rem;
    color:#aaa;
}
.lib-file-wrap{
    margin-bottom:10px;
    padding-bottom:10px;
    border-bottom:1px solid #dfdfdf;
}
.sm-link{
    font-size:0.8rem !important;
}
.danger-link,.danger-link svg,.primary.danger-link svg{
    color:#c5192d !important;
}
.btn--danger{
    background: #c5192d !important;
}
.num-holder>span{
    width:100%;
}
.i-b{
    display:inline-block;
}
td .action-box svg{
    vertical-align:baseline;
    font-size:1rem;
    color:#d0d0d0;
}
td .wfp--link.wfp--link--focus, .wfp--link:focus{
    outline:none !important;
}
td ul{
    vertical-align: middle;
    display: inline-block;
}
.wfp--content-switcher--sm .wfp--content-switcher-btn{
    color:#aaa;
}
.wfp--content-switcher--sm .wfp--content-switcher--selected{
    color:#fff;
}
.m-frame-map-wrap{
    position:relative;
}
.marker-img{
    border-radius:50%;border:3px solid #085387;background:#fff;width:30px;
    height:30px;
    box-shadow: 1px 1px 3px;
}

.marker-img:hover{
    box-shadow:1px 1px 7px;
}
.m-frame-inner-dialog{
    z-index:500;
    width:40%;
    position: absolute;;
    right:20px;
    top:20px;
    bottom:20px;
    overflow:scroll;
    background:#fff;
   /* background:#fff;
    border:1px solid #afafaf;
    border-radius:3px;*/
}
.leaflet-control-dialog .wfp--module__inner{
    height:100% !important;
}

.leaflet-control-dialog .wfp--module__content{
    overflow-y:scroll;
}
.leaflet-control-dialog-close{
    position: absolute;
    right: 15px;
    width: 20px;
    top: 15px;
    height: 20px;
}
.d-n{
    display:none;
}
.wfp--module__title img{
    width:30px;
    vertical-align: middle;
}
.m-frame-inner-dialog li, .m-frame-inner-dialog p{
    font-size:0.9rem;
    line-height: 1.3rem;
}
.m-frame-inner-dialog ul li svg{
    color:#aaa;
}
.m-frame-inner-dialog ul{
    padding:10px;
}
.wide-box,.mid-box{
    width:100%;
    margin:0px auto;
    overflow:hidden;
}
.wide-box .wfp--modal-container{
    width:100%;
    border:1px solid #dfdfdf;
    max-width:95%;
    margin-top:10px;
    overflow:auto;

}
.mid-box .wfp--modal-container{
    width:100%;
    border:1px solid #dfdfdf;
    max-width:65%;
    margin-top:10px;
    overflow:auto;
}

.mid-dialog .wfp--modal-container{

    overflow:visible !important;
}
.s-box .wfp--modal-container{
    width:100%;
    border:1px solid #dfdfdf;
    width:40%;
    margin-top:10px;
    overflow:visible;
}

.wide-box .wfp--modal-content{
    max-height:45rem;
    /*overflow:hidden;*/
    overflow-y:auto;
}
.wide-box-f{
    margin-top:25px;
}
.wide-box-f>div{
    height:95%;
}.wide-box-f>div>div{
    height:97%;
    max-height:100%  !important;
}
.wide-box-f .wfp--modal-container{
    padding:20px !important;
    margin:20px 0px 0px 0px !important;
}
.wide-box-f .wfp--modal-content{
    max-height:100%  !important;
    padding: 0 !important;
    height:100% !important;
    margin-bottom:0 !important;
}
.wide-box .m-frame{
    overflow:scroll;
    max-height:0px;
}
.m-frame{
    overflow-y:auto;
}
.leaflet-container{
    background-color:#75CFEF;
}
.gray-map-wrap .leaflet-container{
    background-color:#c9d2d3 !important;
}
.wfp--btn ~ .wfp--file-container{
    margin-top:0px;
}

/*auto-suggest*/
.auto-suggest-list{
    position:relative;
    overflow: visible;
}
.auto-suggest-list .auto-suggest-ul{
    display:none;
}
.auto-suggest-container{
    overflow:visible;
    width:100%;
}
.auto-suggest-container input{
    width:100%;
}
.auto-suggest-list{

   height:0;
   overflow:visible;
}
.auto-suggest-list.open{
    background: #fff;
    z-index: 100;
    border: 1px solid #dfdfdf;
}
.auto-suggest-list.open .auto-suggest-ul{
    display:block;
}
.auto-suggest-list.open .auto-suggest-ul li{
    background:#fff;
    font-size:0.875rem;
    color:#666;
    cursor:pointer;
    padding:5px;
}
.auto-suggest-list.open .auto-suggest-ul li:first-child,.auto-suggest-list.open .auto-suggest-ul li:hover{
    background-color: #dfdfdf;
}

.act-button{
    color: var(--interactive-01);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    background: var(--inverse-01);
}
/*modal*/
.alert-box.sec-disabled .wfp--btn--secondary{
    display:none;
}
.wfp--input:read-only{
    background:#f3f3f3;
}
.wfp-top-fix{
    padding-top:38px;
}
/*emergency*/
.d-chart-wrap{

}
.stock-import-box{
    height:500px;
    overflow-y:auto;
}
.unit-holder{
    width:100px;
}
.srf-table th{
    text-align:left;
}
.invalid-cell{
    outline-style: solid;
    outline-width:1px;
    outline-color: red;
}
.wfp--module__header{
    position:relative;
}
.year-select-box{
    display:inline-block;
}

.year-select-box select{
    background-color:var(--interactive-01);
    color:#fff;
    font-weight:bold;
    padding:10px;
}
.wfp--module__title .wfp--tabs{
    width:auto;display:inline-block;
    vertical-align: middle;
    margin-left:10px;
}
.c-bth{
    clear:both;
}
.e-action-box{
    float:right;
}
.e-action-box button{
    margin-left:5px;
}
.wfp--module .wfp--module__title{
    width:100%;
}
.login-module .wfp--module__content {
    overflow-y:visible !important;
}
/*emergency*/
/*consignment*/
.srf-cell{
    width:125px;
}
.m-frame-inner-wrap{
    height: 100vh;;
}
.consignment-table td svg{
    font-size:1.1rem;
}
.consignment-table td.checked{
    background:#d4d4d4;
}
/*consignment*/
.wfp--form-item--invalid.wfp--search{
    padding-bottom:3px;
}
.wfp--search.has-bot{
    margin-bottom:0.3rem;
}
.t-c{
    text-align:center;
    text-align-last:center;
}
table td{
    vertical-align: middle;
}
.hidden{
    display:none;
}
.p-info{
    padding:10px 0px;
}
.leaflet-marker-icon{
    background-color: transparent;
    border:none;
}
.leaflet-marker-icon>div img{
    width:100%;
}
.min-w150{
    min-width:150px;
}
.min-w100{
    min-width:100px;
}
.f-h{
    height:100%;

}
.a-h{
    height:auto;
    overflow-y:visible;
}
.f-w .wfp--number__controls{
    width:100%;
}
.with-dropdown .wfp--modal-content{
    min-height:350px;
}
.danger svg{
    color:red !important;
}
.primary svg{
    color:var(--interactive-01) !important;
}
.border-spl{
border-left:1px solid #d0d0d0;
border-right:1px solid #d0d0d0;
}
.c-img-box{
    position:relative;
}
.c-img-box img{
    position:absolute;
    top:0;
    left:0;
    right:0;bottom:0;
    margin:auto;
}
.al-r{
    text-align:right;
}
.m-b-5{
    margin-bottom:5px;
}
.img-100{
    width:100px;
}
.img-70{
    width:70px;
}
.g-box{
    background:#efefef;
}
.collapser{
    cursor:pointer;
    position: absolute;
    top: 50%;
    right: -31px;
    padding: 5px;
    color: var(--interactive-01);
    background: #fff;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: -1px 1px 4px;
    z-index:1;
}
.collapser:hover{
    background:lightblue;
}
.excel-form{
    background-color: #fff;

    padding:5px;
    padding-bottom:20px;
}
.t-a-r{
    text-align:right !important;
}
.t-a-c{
    text-align:center !important;
}
.t-a-l{
    text-align:left !important;
}
.f-bold{
    font-weight:bold;
}
.q-help{
position: fixed;
    top: 59px;
    right: 0px;
    font-size: 2em;
    z-index: 8009;
    cursor:pointer;
    border-radius: 50% 0 0 50%;
    box-shadow: 1px 2px 4px #000;
    background: #fd7e14;
    color: #fff;
    padding: 5px 5px 5px;
    height:auto !important;
}


/*stock*/
.leaflet-map-pane svg{
    cursor:pointer !important;
}
.mframe-filter-wrap{
    overflow: visible;
}
@media screen and (max-width: 600px)
{
   .m-frame-map-wrap{
       height:92vh !important;
   }
   .m-frame{
       height:auto !important;
   }
   .collapser{
       display:none !important;
   }
}
.mid-box .wfp--modal-content {
    max-height: 30rem;
    overflow-y: scroll;
}
@media screen and (max-height: 768px)
{
    .wide-box .wfp--modal-content {
        max-height: 30rem;
        overflow: scroll;
    }
}

@media screen and (max-width: 992px)
{
    .m-frame-map-wrap{
        height:92vh !important;
    }
    .m-frame{
        height:auto !important;
    }
    .collapser{
        display:none !important;
    }
}

/*stock*/
/*forms*/
.chart-outter{
    overflow:visible;
    height:auto;
}
.chart-outter>div{
    border:1px solid #ddd;
    margin:10px 0px;
}
td.to-delete{
    display:none;
}
.inner-table{
    border:2px solid #777;
   /* border-left:none;*/
    border-left:5px solid #0b77c1;
}
.inner-table thead, .inner-table th{
    border-bottom:none !important;
}
.inner-table th,.inner-table th>div.t-head{
    font-size:0.7rem;
}
.double-table th{
    font-size:12px !important;
}
.import-box .wfp--modal-header>div{
    width:100%;
}
.import-box .wfp--modal-content{
    min-height:200px;
}
.import-box .wfp--modal-header>div button{
vertical-align:top;
margin-left:10px;
}
.import-box .wfp--modal-header>div .ss-search{
    font-size:0.875rem;
}
.in-input-box{
    height: calc( 100% - 1px);
    width: calc( 100% - 1px);
    margin-left: 1px;
    border: none;
    margin-top: 1px;
}
.no-focus{
    border:none;
}
.in-input-box:focus,.no-focus:focus{
    outline:none;
}
.auto-input{
    width:calc(100% - 1px);
    min-height:calc(100% - 1px);
    border:none;
    margin-left:1px;
    margin-top:1px;
}
.auto-input:focus{
    outline:none;
}
.submit-normal{
    width:100px;
    height:40px;
}
label.wfp--label span.una {
    font-weight: normal !important;
    color: #a0a0a0;
}
.disabled-cell{
    background:#eee;
}
.read-only-form .disabled-cell{
    background:#fff !important;
}
.excel-form  .wfp--number,td .wfp--number .wfp--input-wrapper,td .wfp--number .wfp--number__controls{
    width:100%;height:100%;
}
.excel-form  .wfp--form-item--invalid{
    padding:0px;
}
.excel-form  .wfp--number input[type='number']{
    height:100%;
    width:100%;
}
.excel-form  .wfp--number__control-btn{
    height:100%;
}
.scroll-form,.wfp--module .wfp--module__content.scroll-form{
    max-height:max-content !important;
}
.l-15-rows .w-cell{
    min-height:20px;
}
.l-15-rows .w-cell>div{
    min-height:100%;
}
.form-title{
   /* padding:20px 0px 20px 0px;*/
    font-size:15px;
    font-weight:bold;
    text-align:center;
}
.form-body, .form-body div{
    font-size:12px;
    line-height:17px;
}
.form-section{
    padding-top:17px;
}
.blank-row{
    height:17px;
}
.meta-label-left{
    width:100px;
    font-weight:bold;
    padding:0px 4px !important;
    font-size:10px !important;
}
.meta-label-left-inline{
    width:100%;
    font-weight:bold;
    padding:0px 4px !important;
    font-size:10px !important;
}
.meta-label-right,.meta-label-right *{
    font-weight:bold;
    text-align:right;
    width:100px;
    display:inline-block;
    padding-right:5px;
    font-size:10px !important;
}
.meta-label-right-inline{
    font-weight:bold;
    text-align:right;
    width:100%;
    display:inline-block;
    padding-right:5px;
    font-size:10px !important;
}
td.meta-label-right-inline,td.meta-label-left-inline,td.meta-label-left,td.meta-label-right{
    display:table-cell;
}
.meta-field-wrap{
    box-shadow:  1px 0 0 0 #eee,
    0 1px 0 0 #eee,
    1px 1px 0 0 #eee,   /* Just to fix the corner */
    1px 0 0 0 #eee inset,
    0 1px 0 0 #eee inset;
}
.l-15-rows .meta-field-wrap{
    padding:1px 2px !important;
}
.no-b-r{
    border-right:none !important;
}
.no-b-b{
    border-bottom:none !important;
}
.meta-field-b{
    border:1px solid #000;
}
.meta-field-left{
    width: calc( 100% - 100px);
    display:inline-block;
    box-shadow:  1px 0 0 0 #eee,
    0 1px 0 0 #eee,
    1px 1px 0 0 #eee,   /* Just to fix the corner */
    1px 0 0 0 #eee inset,
    0 1px 0 0 #eee inset;

    padding:0px 4px !important;
    min-height:20px;
}
.row-fix{
    margin:0px;
}
.cell-fix{
    padding:0px;
}
.meta-pd{
    padding:3px 4px !important;
}
.meta-field-group{
    height:67px;
}
.form-body .row{
    margin:0px;
}
.form-body .row>div{
    padding:0px;
    min-height:20px !important;
}
.h-50{
    height:50%;
}
.form-inline-table{
    width:100%;

}
.form-inline-table tr td{
    /*border:1px solid;
    border-bottom:none !important;
    border-right:none !important;*/ box-shadow:  1px 0 0 0 #eee,
    0 1px 0 0 #eee,
    1px 1px 0 0 #eee,   /* Just to fix the corner */
    1px 0 0 0 #eee inset,
    0 1px 0 0 #eee inset;
    display:table-cell !important;
}
.select-center{
    text-align-last:center;
}
.bg-wh{
    background:#fff;
}
.w-4in{
    width:4in;
}
.min-h-23p{
    min-height:23px;
}
.w-150p{
    width:150px;
}
.w-100-20p{
    width: calc( 100% - 20px);
}
.w-100-40p{
    width: calc( 100% - 40px);
}
.w-100-50p{
    width: calc( 100% - 50px);
}
.w-50p{
    width: 50px;
}
.w-40p{
    width: 40px;
}
.w-20p{
    width: 20px;
}
.w-200p{
    width:200px;
}
.w-100p{
    width:100px;
}
.w-75p{
    width:75px;
}
.w-70p{
    width:70px;
}
.d-i-b{
    display:inline-block;
}
.m-t-20{
    margin-top:20px;
}
.m-l-10p{
    margin-left:10px;
}
.pd-t-15p{
    padding-top:15px !important;
}
.pd-t-5p{
    padding-top:5px;
}
.pd-b-10p{
    padding-bottom:10px;
}
.pd-t-20p{
    padding-top:20px;
}
.pd-10{
    padding:10px;
}
.pd-3-4{
    padding:3px 4px;
}.pd-1p{
    padding-left:1px;
}
.pd-5p{
    padding:5px !important;
}
.pd-2p{
    padding:2px;
}
.pd-15p{
    padding:15px;
}
.normal-link{
    text-decoration: underline !important;
    color:blue !important;
    cursor:pointer;
}
.u:visited{
    color:blue !important;
}
.drop-zone-fix{
    padding:7px 07px 0px !important;
}
.submit-large{
    width:85%;
    height:80%;
}
.tb-c{
    display:table-cell;
}

.w-50p{
    width:50px;
}
@media screen and (max-width: 600px)
{

}
.excel-form .SingleDatePicker_1{
    width:100%;
}
.date-wrap-top .SingleDatePicker_picker{
    top:-300px !important;
    left:-330px !important;
  }
  .date-wrap-top .DateInput_fang{
    top:-8px !important;
    transform: rotate(180deg) !important;
}

div.date-holder .SingleDatePickerInput{
    padding-left:1px !important;
}
.date-holder .DateInput_input{
   padding:0px;
    height:auto;
    text-align:center;
    border:none;
}
.date-holder .SingleDatePicker .DateInput, .date-holder .SingleDatePickerInput{
    width:100%;
    padding:1px !important;
}
.date-holder .SingleDatePicker .DateInput_input{
    height:auto;
    width:100%;

    display:block;
    font-size:0.65rem;
    text-align:center;
    border:none;
}
.num-holder{
    padding:1px !important;
}
.num-holder .wfp--number input[type='number']{
    border:none !important;
    height:auto !important;
}
.num-holder .wfp--form-item--invalid{
    padding:0px !important;
}
.num-holder .wfp--number__control-btn{
    height:100% !important;
}
.num-holder .wfp--number__controls {
    width:100% !important;
}
.wfp--number__controls {
    width:100% !important;
}
.sselect-holder .ss-single-selected{
    width:100%;
    padding:0px;
    height:100%;
    border:none;
}

.sselect-holder .ss-main, .sselect-holder .ss-option, .sselect-holder .placeholder{
    font-size:0.75rem;
}
.sselect-holder .ss-main{
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    padding:1px;

}
.sselect-holder .placeholder .ss-disabled{
    padding:2px;
}
.sselect-holder{
    height: 20px;
    position: relative;
    padding:0px !important;
}
.excel-form td select{
    width:100%;
    height:100%;
    border:none;
    background-color: #fff;
}

.excel-form td select:focus{
    outline:1px solid #777;

}
.excel-form td.select-holder{
    padding:2px 1px 1px 2px;
}
.comment-holder{
    min-height:50px;
}
.comment-holder>div,.comment-holder>div>div{
    min-height:50px;
}
.comment-holder>div>div{
    padding:5px;
}
.min-h-23p{
    min-height:23px;
}
.min-h-400p{
    min-height:400px;
}
tr.expandable > td {
    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
    padding: 0;
  }

  tr.expandable > td > .inner {
    border-left: 5px solid #0b77c1;

    overflow: hidden;
  }

/*forms*/
/*chart*/
 .c-legend{
     width:100%;
 }

 .c-legend> ul{
     padding: 0;
     text-align: left;
 }
.bg-text-c{
    position:absolute;
    top:49%;
   margin:0px auto;
   width:100%;
   text-align:center;
   color:#ddd;
}

 .c-legend {
   width:100%;
   bottom:10%;
 }
 .c-legend li {
     /*cursor: pointer;*/
     margin: 4px 3px;
     display: inline-table;
 }
 .c-legend li span {
     position: relative;
     padding: 0.2rem 1rem;
     color:#777;
     z-index: 2;
     font-size: 11px;
     text-align:left;
 }
 .c-legend li span.lg-b {

    color: white;
}

 .c-legend{
     height: 100%;
     overflow-y: auto;
 }
/*chart*/
/*react-select*/
.def-select{
    width:100%;
}
.no-cornor .select__control,.def-select>div{
    border-radius:0 !important;
}
.select__control{
    font-size:0.87rem;
}
/*react-select*/
/*helper*/
.sselect-holder div {
    padding: 0px !important;
    min-height: 0 !important;
    border: none;
}
.sselect-holder>div {
    padding: 1px !important;
}
.full-dialog{
    height:35em;
    overflow-y:auto;
}
.wfp--form-item>div{
    width:100%;
}
.wfp--form-item .select__value-container{
    padding:5px 7px;
}
.select__control{
    border-radius:0 !important;
}
.SingleDatePicker_picker{
    z-index:2;
}
.z-top{
    z-index:50000;
}
.f-w .SingleDatePickerInput,.f-w .SingleDatePicker,.f-w .SingleDatePicker .DateInput{
    width:100%;
}
.f-w .DateInput_input{
    padding:10px;
}
.min-h-80{
  min-height:80%;
}
.min-h-400p{
  min-height:400px;
}
.p-r{
position:relative;
}
.inline-filter-wrap {
    width: auto;
    display: inline-block;
    padding: 5px;
    vertical-align: bottom;
}
.btn-link{
    cursor:pointer;
    text-decoration: underline !important;
    color:var(--interactive-01) !important;
    margin-right:10px;
}
.b-stack-wrap button{
    margin-bottom:2px;
}
.scroll-y-auto{
    overflow-y:auto;
}
.wfp--module .wfp--module__content{
    overflow-y:auto;
}
.r-select-box{
    width:100%;
    padding:7px;
    border:1px solid #ddd;font-size:0.875rem;

}.wfp--form-item--invalid .r-select-box{
    border:1px solid var(--support-01);
}
.ss-content .ss-list .ss-option.ss-disabled{
   /* display:none;*/
}
.fade-in {
    opacity:1;
    -webkit-transition : all 2s ease-out;
    -moz-transition : all 2s ease-out;
    -o-transition : all 2s ease-out;
    transition : all 2s ease-out;
}
.bg-lb{
    background:lightblue;

}
.blink-item {
    opacity: 0;
    animation: blinking 1s linear infinite;
  }

  @keyframes blinking {
    from,
    49.9% {
      opacity: 0;
    }
    50%,
    to {
      opacity: 1;
    }
  }

@keyframes blinkred {
   /* 0% { box-shadow: 0 0 5px red inset; }
    50% { box-shadow: none; }
    100% { box-shadow: 0 0 5px red inset; }*/
    0% { outline:2px solid red }
    50% {outline:0}
    100% { outline:2px solid red  }
}

@-webkit-keyframes blinkred {
    0% { outline:2px solid red }
    50% {outline:0}
    100% { outline:2px solid red  }
    /*0% { box-shadow: 0 0 5px red inset; }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow: 0 0 5px red inset; }*/
}

.invalid-blink {
    -webkit-animation: blinkred 1.0s linear infinite;
    -moz-animation: blinkred 1.0s linear infinite;
    -ms-animation: blinkred 1.0s linear infinite;
    -o-animation: blinkred 1.0s linear infinite;
    animation: blinkred 1.0s linear infinite;
}
.alert-box{
    z-index:10000 !important;
}
.logo{
    width:75px;
}
.wfp--modal-container{
    overflw:visible;
}
.pad-2p{
    padding:2px;
}
.no-pad{
    padding:0px !important;
}
.tb-w{
    display:table;
    width:100%;
    height:100%;
}
.v-mid{
    vertical-align:middle;
    text-align:center;
}
.v-mid.num{
    text-align:right;
}
.i-fix{
    margin-left:-10px;
    opacity:0;
}
.short-num{
    width:50px;
}
.wrap-stock-table {
    overflow-y:auto;height:100%;
}

.label-wrap{
    height: 6.2in;
    margin-bottom:5px;
    width:100%;
    padding:0px 10px;
}
.list-table-frame .table-rel-wrap {
    width:100%;
    min-height:300px;
}
.wfp--main-navigation,.sidebar{
    z-index:10000;
}
.data-rows-wrap{
    min-height:200px;
}
.pl-rows-wrap{
    min-height:250px;
}
.num-input{
    width:100%;
    text-align: right;
}
.num-input-small{
    width:50px;
    text-align:right;
}
.test-mode{
    background:gray
}
.table-scroll-wrap{
    width:100%;
    height:400px;
    overflow-y:scroll;
}
.leaflet-grab {cursor: auto;}
.leaflet-dragging .leaflet-grab {cursor: hand;}
.screen-wrap{
    position:absolute;
    top:0px;left:0px;height:100%;
    width:100%;
}
/*helper*/
/*draggable modal*/
.flexible-modal {
    position: absolute;
    z-index: 10001 !important;
    border: 1px solid #ccc;
    background: white;
    border-radius:9px;
  }

  .flexible-modal-mask {
    /*position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;*/
    display:none;
  }
  .flexible-modal iframe{
      margin-top:42px;
  }
  .flexible-modal .wfp--modal-close{
      right:0.5rem !important;
      top:0.2rem !important;
      z-index:1;

  }
  .flexible-modal-resizer {
    position:absolute;
    right:0;
    bottom:0;
    cursor:se-resize;
    margin:5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
  }
  .flexible-modal-drag-area{
    background: var(--ui-03) !important;
    height: 40px !important;
    position:absolute;
    right:0;
    top:0;
    left:0;
    width:auto !important;
    cursor:move;
  }
  .flexible-modal-drag-area-left,.flexible-modal-drag-area-right,.flexible-modal-drag-area-bottom{
      display:none;
  }


/*draggable modal*/
/*wfpfix*/
.wfp--content-switcher--sm .wfp--content-switcher-btn{
    color:#777;
}
.wfp--content-switcher--sm .wfp--content-switcher-btn.wfp--content-switcher--selected{
    color:#fff;
}
.wfp--modal-content{
    /*margin-bottom:0px;*/
   /* border:1px solid #aaa;*/
}
.alert-box .wfp--modal-content{
    border:none;
}
.m-frame-filter-wrap>.select-frame{
    max-height:3em;
}
.m-frame-filter-wrap .wfp--form-item .wfp--label{
    margin-bottom:0.2rem;
}
/*wfpfix*/
/*pl*/
.label-frame{
    overflow:hidden;
}
/*pl*/
html,body{
    height:100%;
}
#root,#root>div,.main-wrap,.main>.wfp--module,.main>.wfp--module>.wfp--module__inner{
    height:100%;
}
.main>.wfp--module{
    padding:0px !important;
}
.main>.wfp--module>.wfp--module__inner{
    overflow:auto;
}
.main>.wfp--module>.wfp--module__inner>.wfp--module__content
{
    height:calc( 100% - 70px);
}
.main{
    padding:62px 10px 10px 10px;
/*


    position:absolute;
    top:0px;
    right:0px;left:0px;bottom:0px; */
    height:100%;
    width:100%;
  }
.main>.m-frame{
    height:100%;
    /* height:92vh; */
}
.main>.m-frame>.m-frame-inner-wrap{
    overflow-x: auto;
    overflow-y:auto;
    height:100%;
}
.main>.m-frame>.m-frame-filter-wrap{
    overflow:visible !important;
}
.md-view .md-view-body{
    width:100%;
  }
.tp-align td{
    vertical-align: top;
}

.trc-main-color{
    background-color:#0099d9 !important;
}